import { createRouter, createWebHashHistory } from "vue-router";
import { useUserStore } from "@/store/userStore";

const routes = [
  {
    path: "/",
    name: "appshell",
    component: () => import("../views/appShell.vue"),
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/homeView.vue"),
        meta: {
          requiresAuth: true,
          desc: "Inicio",
        },
      },
      {
        path: "ica",
        name: "ica",
        redirect: "/ica/list",
        meta: {
          requiresAuth: true,
          desc: "Medición ICA",
        },
        children: [
          {
            path: "add",
            name: "ica_add",
            component: () => import("../views/ica/icaAddView.vue"),
          },
          {
            path: "list",
            name: "ica_list",
            component: () => import("../views/ica/icaListView.vue"),
          },
          {
            path: "det/:uuid/:view?",
            name: "ica_det",
            component: () => import("../views/ica/icaDetView.vue"),
          },
        ],
      },
      {
        path: "icv",
        name: "icv",
        redirect: "/icv/list",
        meta: {
          requiresAuth: true,
          desc: "Medición ICV",
        },
        children: [
          {
            path: "add",
            name: "icv_add",
            component: () => import("../views/icv/icvAddView.vue"),
          },
          {
            path: "list",
            name: "icv_list",
            component: () => import("../views/icv/icvListView.vue"),
          },
          {
            path: "det/:uuid/:view?",
            name: "icv_det",
            component: () => import("../views/icv/icvDetView.vue"),
          },
        ],
      },
      {
        path: "nod",
        name: "nod",
        redirect: "/nod/list",
        meta: {
          requiresAuth: true,
          desc: "Registro NOD",
        },
        children: [
          {
            path: "add",
            name: "nod_add",
            component: () => import("../views/nod/nodAddView.vue"),
          },
          {
            path: "list",
            name: "nod_list",
            component: () => import("../views/nod/nodListView.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/loginView.vue"),
    meta: {
      desc: "Login",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/logoutView.vue"),
    meta: {
      desc: "Logout",
    },
  },
  {
    path: "/noaccess",
    name: "noaccess",
    component: () => import("../views/noaccessView.vue"),
    meta: {
      desc: "Noaccess",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "nofound",
    component: () => import("../views/nofoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  const { uid } = useUserStore();
  if (to.meta.requiresAuth && !uid) return "/login";
});

export default router;
