import "./styles/quasar.sass";
import "./styles/app.sass";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import langEs from "quasar/lang/es";
import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
// Cambiado el color primario a indigo-10
export default {
  config: {
    brand: {
      primary: "#1a237e",
      // ...
    },
  },
  plugins: { Notify },
  lang: langEs,
};
