import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import { auth } from "@/firebase";

export const useUserStore = defineStore("userStore", () => {
  const uid = useStorage("uid", null);
  const name = useStorage("name", null);
  const only_view = useStorage("only_view", false);
  const fview = useStorage("fview", false);
  const admin = useStorage("admin", false);

  let unsubscribe = null;
  const router = useRouter();

  const setuser = (payload) => {
    uid.value = payload.uid;
    name.value = payload.name;
    only_view.value = payload.only_view;
    admin.value = payload.admin;
    fview.value = payload.fview;
  };
  const clearuser = () => {
    uid.value = null;
    name.value = null;
    only_view.value = null;
    admin.value = null;
    fview.value = null;
    signOut(auth);
    setTimeout(() => router.push({ name: "login" }), 4000);
  };
  const binduser = () => {
    unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdTokenResult()
          .then((tokenResult) => {
            if (!tokenResult.claims.allow_access) clearuser();
          })
          .catch(() => {});
      } else clearuser();
    });
  };
  const unbinduser = () => unsubscribe();

  return {
    uid,
    name,
    only_view,
    admin,
    fview,
    setuser,
    clearuser,
    binduser,
    unbinduser,
  };
});
