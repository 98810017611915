import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import router from "./router";
import "./registerServiceWorker";

createApp(App)
  .use(createPinia())
  .use(router)
  .use(Quasar, quasarUserOptions)
  .mount("#app");
