import { initializeApp } from "firebase/app";
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  CACHE_SIZE_UNLIMITED,
} from "firebase/firestore";
import { initializeAuth, indexedDBLocalPersistence } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAzazuamUUJ5EvLiBGf2xAJNhxpL6qYyvI",
  authDomain: "bogado-d3481.firebaseapp.com",
  projectId: "bogado-d3481",
  storageBucket: "bogado-d3481.appspot.com",
  messagingSenderId: "719519896371",
  appId: "1:719519896371:web:c356de5f11f3cd34646446",
  measurementId: "G-Y8DKVKHLES",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  }),
});
const auth = initializeAuth(app, {
  persistence: indexedDBLocalPersistence,
});
export { db, auth };
